import fullClient from 'algoliasearch'
import algoliasearch from 'algoliasearch/lite'
import qs from 'qs'
import { pick } from 'lodash'
import aa from 'search-insights'
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import { SHOPIFY_CUSTOMER_TOKEN_COOKIE } from '@framework/const'

const appId = process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID!
const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY!

// create algolia search client
export const SearchClient = algoliasearch(appId, apiKey)
export const algoliaClient = () => fullClient(appId, apiKey)

aa('init', {
  appId,
  apiKey,
})

export const searchInsights = aa

export const getAlgoliaUserToken = () => {
  const userToken = Cookies.get(SHOPIFY_CUSTOMER_TOKEN_COOKIE)
  if (userToken) return userToken as string

  const anonymousToken = Cookies.get('algolia_anonymous_token')
  if (anonymousToken) return anonymousToken as string

  const newToken = uuidv4()
  Cookies.set('algolia_anonymous_token', newToken)
  return newToken
}

export const getFilterAttributeTitle = (attribute: string) => {
  const title = attribute
  switch (attribute?.toLowerCase()) {
    case 'named_tags.band_material':
    case 'named_tags.material_type':
      return 'Material'
    case 'named_tags.case_colour':
      return 'Colour'
    case 'named_tags.category':
      return 'Product Categories'
    case 'named_tags.metal_type':
      return 'Metal'
    case 'named_tags.metal_colour':
      return 'Colour'
    case 'named_tags.maincategory':
      return 'Category'
    default:
      return title?.replace('named_tags.', '').replace('_', ' ')?.toLowerCase()
  }
}

export const getFilterAttributeUUID = (attribute: string) => {
  return attribute.replace('.', '_').toLowerCase()
}

export const transformFilters = (attribute: string, items: any[]) => {
  switch (attribute) {
    case 'price_range':
      return items.map((item) => ({
        ...item,
        label: '$' + item.label.replace(':', ' - $'),
      }))
    default:
      return items
  }
}

export const searchStateToURL = (searchState: any) => {
  if (searchState) {
    const selectiveState = pick(searchState, [
      'q',
      'refinementList',
      'sortBy',
    ])

    return Object.keys(selectiveState).length
      ? `?${qs.stringify(selectiveState)}`
      : ''
  }

  return ''
}
export const debounceTime = 700
export const createURL = (state: any) => `?${qs.stringify(state)}`
export const pathToSearchState = (path: string) =>
  path.includes('?') ? qs.parse(path.substring(path.indexOf('?') + 1)) : {}

export default SearchClient

export const getDefaultSearchString = (searchString?: string) => {
  if (!searchString) return `NOT named_tags.search:hidden`

  return `${searchString} AND NOT named_tags.search:hidden`
}
