import { Container } from '@components/ui'
import SocialIcons from '@components/ui/SocialIcons'
import cn from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'
import SubscribeForm from '../SubscribeForm'
import s from './Footer.module.css'
import {
  AfterPay,
  Amex,
  MasterCard,
  PayPal,
  Visa,
  Zip,
} from '@components/icons'
interface Props {
  className?: string
  footer?: any
}
interface footerItems {
  items: footerItem[]
  primary: {
    Heading: string
  }
}
interface footerItem {
  Link?: string
  LinkTitle?: string
}
const Footer: FC<Props> = ({ className, footer }) => {
  const { asPath } = useRouter()
  const footerItems = footer?.data?.slices
  const rootClassName = cn(s.root, className)
  let footerBGColour = '#fff'

  if (asPath === '/')
    footerBGColour = footer?.data?.homepageBrushColour || '#fff'
  if (asPath.includes('/me')) footerBGColour = '#f5f5f5'

  return (
    <footer className={rootClassName}>
      <div className="bg-brand-grey w-full">
        <Container>
          <div className="grid grid-cols-2 py-10 lg:py-12 lg:grid-cols-6 lg:gap-3">
            <div className="hidden lg:block body-1-bold text-brand-dark-grey pt-8 pb-1 lg:pt-0">
              <div className="pb-4">Hoskings Jewellers</div>
              <SocialIcons width={16} height={16} fill="#464544" />
              <div className="body-1 mt-2 lg:mt-3 mb-4">1800 819 796</div>
            </div>
            {footerItems?.map((item: footerItems, index: number) => {
              return (
                <div key={index}>
                  <div className="body-3-bold uppercase text-brand-darkest-grey pt-8 pb-1 lg:pt-0 first: row-span-2 last:col-span-1">
                    {item.primary.Heading}
                  </div>
                  {item?.items.map((navItem: any, index: number) => {
                    return (
                      <Link
                        key={index}
                        href={navItem?.Link || '/'}
                        passHref
                        legacyBehavior
                      >
                        <a className="block body-2 mt-4 text-brand-dark-grey cursor-pointer hover:opacity-75">
                          {navItem.LinkTitle}
                        </a>
                      </Link>
                    )
                  })}
                </div>
              )
            })}
            <div className="lg:col-start-5 lg:col-span-2 row-start-1 col-span-2 text-brand-dark-grey">
              <div className="grid grid-cols-1">
                <div className="font-normal text-24 leading-8">
                  Beautiful emails
                </div>
                <div className="body-1 mt-2 lg:mt-3 mb-4">
                  Sign up to receive exclusive offers, VIP invites and news
                </div>
                <div className="pb-4">
                  <SubscribeForm />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center pb-6 lg:pb-0 lg:flex-row">
            <div className="lg:order-1 order-3 flex items-center body-2 text-brand-dark-grey py-6">
              <Link href="/help/privacy-policy" passHref legacyBehavior>
                <a className="block body-2 text-brand-dark-grey cursor-pointer hover:opacity-75">
                  Privacy Policy
                </a>
              </Link>
              <div className="mx-4">|</div>
              <Link href="/help/terms-and-conditions" passHref legacyBehavior>
                <a className="block body-2 text-brand-dark-grey cursor-pointer hover:opacity-75">
                  Terms & Conditions
                </a>
              </Link>
              <div className="mx-4">|</div>
              <Link href="/help/cookie-policy" passHref legacyBehavior>
                <a className="block body-2 text-brand-dark-grey cursor-pointer hover:opacity-75">
                  Cookie Policy
                </a>
              </Link>
            </div>
            <div className="order-4 body-2 text-brand-dark-grey lg:ml-auto lg:order-2">
              &#169; {` ${new Date().getFullYear()} Hoskings Jewellers`}
            </div>
            <div className="pt-2 pb-6 lg:order-4 order-1 lg:hidden flex flex-col items-center">
              <span className="hidden sm:inline mb-3">1800 819 796</span>
              <a href="tel:+611800819796" className="inline sm:hidden mb-3">
                1800 819 796
              </a>
              <SocialIcons width={16} height={16} fill="#464544" />
            </div>
            <div className="pb-6 flex flex-row gap-2 lg:pb-0 lg:order-3 order-2 lg:ml-auto">
              <PayPal />
              <MasterCard />
              <Visa />
              <AfterPay />
              <Zip />
              <Amex />
            </div>
          </div>
        </Container>
      </div>
      <style jsx>
        {`
          .footerBrushContainer {
            background-color: ${footerBGColour};
          }
        `}
      </style>
    </footer>
  )
}
export default Footer
